@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;
@use 'iro-design/src/mixins' as mx;
@use 'include-media/dist/include-media' as media;

@include iro.props-namespace('attachment') {
    @include iro.props-store((
        --colors: (
            --bg:       fn.global-color(--obj-hi),
            --video-bg: #000,
        ),
    ), 'colors');

    @include iro.props-store((
        --colors: (
            --bg: fn.global-color(--bg-hi2),
        ),
    ), 'colors-dark');

    @include iro.bem-object(iro.props-namespace()) {
        position:      relative;
        overflow:      hidden;
        border-radius: fn.foreign-dim(--message, --bubble --rounding);

        @include iro.bem-elem('notice') {
            display:          flex;
            position:         absolute;
            z-index:          5;
            top:              0;
            left:             0;
            flex-direction:   column;
            align-items:      center;
            justify-content:  center;
            width:            100%;
            height:           100%;
            background-color: rgba(#000, .75);
            color:            #fff;
            backdrop-filter:  blur(3em);
        }

        @include iro.bem-elem('hide-btn') {
            position: absolute;
            z-index:  10;
            top:      .5em;
            right:    .5em;
        }
        
        @include iro.bem-elem('object') {
            display:          block;
            position:         relative;
            width:            100%;
            height:           100%;
            max-height:       50vh;
            background-color: fn.color(--bg);
            aspect-ratio:     21 / 9;

            @include iro.bem-modifier('video') {
                background-color: fn.color(--video-bg);
            }
        }
        
        @include iro.bem-elem('img') {
            display:    block;
            object-fit: scale-down;
            width:      100%;
            height:     100%;
        }
    }
}
