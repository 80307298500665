@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;
@use 'include-media/dist/include-media' as media;

@include iro.props-namespace('attachment-list') {
    @include iro.props-store((
        --dims: (
            --spacing:    fn.global-dim(--size --150),
            --item-width: fn.global-dim(--size --2800),

            --compact: (
                --item-width: fn.global-dim(--size --2400),
            ),
        )
    ), 'dims');

    @include iro.bem-layout(iro.props-namespace()) {
        display:        flex;
        flex-direction: column;
        gap:            fn.dim(--spacing);

        @include iro.bem-modifier('grid') {
            display:               grid;
            grid-template-columns: repeat(auto-fit, minmax(fn.dim(--item-width), 1fr));
            grid-auto-rows:        auto;
        }

        @include iro.bem-modifier('grid-3') {
            display:               grid;
            grid-template-columns: 2fr 1fr;
            grid-template-rows:    1fr 1fr;
            grid-template-areas:   'first second' 'first third';

            :first-child {
                grid-area: first;
            }

            :nth-child(2) {
                grid-area: second;
            }

            :nth-child(3) {
                grid-area: third;
            }
        }

        @include iro.bem-modifier('compact') {
            grid-template-columns: repeat(auto-fit, minmax(fn.dim(--compact --item-width), 1fr));
        }
    }
}
