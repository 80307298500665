@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;

@include iro.props-namespace('event-message') {
    @include iro.props-store((
        --dims: (
            --avatar:  fn.foreign-dim(--avatar, --100 --size),
            --spacing: fn.foreign-dim(--message-group, --spacing-x),
        )
    ), 'dims');

    @include iro.props-store((
        --colors: (
            --fg: fn.global-color(--fg-hi),
        ),
    ), 'colors');

    @include iro.bem-object(iro.props-namespace()) {
        display:     flex;
        gap:         fn.dim(--spacing);
        align-items: baseline;
        color:       fn.color(--fg);

        @include iro.bem-elem('time') {
            flex: 0 0 auto;
        }

        @include iro.bem-elem('icon') {
            flex: 0 0 auto;
        }
    }
}
