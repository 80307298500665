@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;

@include iro.props-namespace('overlay-button') {
    @include iro.bem-object(iro.props-namespace()) {
        visibility: hidden;
        transform:  scale(.9);
        transition: transform .2s ease, opacity .2s ease, visibility 0s .2s linear;
        opacity:    0;

        @include iro.bem-is('visible') {
            visibility: visible;
            transform:  scale(1);
            transition: transform .2s ease, opacity .2s ease, visibility .2s linear;
            opacity:    1;
        }
    }
}
