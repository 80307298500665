@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;

@include iro.props-namespace('main') {
    @include iro.props-store((
        --dims: (
            --pad: fn.global-dim(--size --250),
    
            --content: (
                --width: iro.fn-px-to-rem(900px),
                --pad-y: fn.global-dim(--size --250),
            )
        )
    ), 'dims');

    @include iro.bem-layout(iro.props-namespace()) {
        display:          flex;
        position:         relative;
        grid-area:        main;
        flex-direction:   column;
        min-width:        0;
        min-height:       0;
        scrollbar-gutter: stable;

        @include iro.bem-elem('content') {
            flex:    1 1 auto;
            padding: fn.dim(--pad);

            @include iro.bem-modifier('narrow') {
                box-sizing:   border-box;
                width:        100%;
                max-width:    fn.dim(--content --width);
                margin-right: auto;
                margin-left:  auto;
            }
        }
        
        @include iro.bem-modifier('centered') {
            align-self: center;
        }
    }
}
