@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;

@include iro.props-namespace('sticky-header') {
    @include iro.props-store((
        --dims: (
            --pad: calc(fn.foreign-dim(--main, --pad) + fn.foreign-dim(--header, --height)),
        )
    ), 'dims');

    @include iro.bem-layout(iro.props-namespace()) {
        display:  flex;
        position: sticky;
        z-index:  5000;
        top:      fn.dim(--pad);
    }
}
