@use 'iro-design/src/functions' as fn;

body {
    display:               grid;
    grid-template-columns: auto auto minmax(0, 1fr) auto auto;
    grid-template-rows:    1fr;
    grid-template-areas:   'left left-handle main right-handle right';
    box-sizing:            border-box;
    height:                100%;
    margin:                0;
    padding:               0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color:       fn.color(--fg-lo);
    font-weight: 700;
}

pre,
code {
    font-size: fn.dim(--font-size --100);
}
