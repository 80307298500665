@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;

@include iro.props-namespace('resize-handle') {
    @include iro.props-store((
        --dims: (
            --width:  5px,
            --hitbox: 12px,
        )
    ), 'dims');

    @include iro.props-store((
        --colors: (
            --bg: fn.global-color(--fg-hi2),
        ),
    ), 'colors');

    @include iro.bem-object(iro.props-namespace()) {
        display:    block;
        position:   relative;
        z-index:    5000;
        transition: opacity .2s ease;
        opacity:    0;
        cursor:     ew-resize;

        &::after {
            content:  '';
            position: absolute;
            top:      0;
            left:     calc(-.5 * fn.dim(--hitbox));
            width:    fn.dim(--hitbox);
            height:   100%;
        }

        &::before {
            content:          '';
            position:         absolute;
            top:              0;
            left:             calc(-.5 * fn.dim(--width));
            width:            fn.dim(--width);
            height:           100%;
            background-color: fn.color(--bg);
        }

        &:hover,
        &:active {
            transition: opacity .2s ease .2s;
            opacity:    1;
        }

        @include iro.bem-modifier('left') {
            grid-area: left-handle;
        }

        @include iro.bem-modifier('right') {
            grid-area: right-handle;
        }
    }
}
