@font-face {
    font-family:  'IBM Plex Sans';
    font-style:   normal;
    font-weight:  normal;
    font-display: swap;
    src:          url('../../assets/IBMPlexSans-Regular.woff2') format('woff2');
}

@font-face {
    font-family:  'IBM Plex Sans';
    font-style:   italic;
    font-weight:  normal;
    font-display: swap;
    src:          url('../../assets/IBMPlexSans-Italic.woff2') format('woff2');
}

@font-face {
    font-family:  'IBM Plex Sans';
    font-style:   normal;
    font-weight:  500;
    font-display: swap;
    src:          url('../../assets/IBMPlexSans-Medium.woff2') format('woff2');
}

@font-face {
    font-family:  'IBM Plex Sans';
    font-style:   normal;
    font-weight:  bold;
    font-display: swap;
    src:          url('../../assets/IBMPlexSans-Bold.woff2') format('woff2');
}
