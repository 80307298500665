@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;

@use 'iro-design/src/objects/avatar';
@use 'iro-design/src/objects/message';

@include iro.props-namespace('message-list') {
    @include iro.props-store((
        --dims: (
            --avatar:      fn.foreign-dim(--avatar, --100 --size),
            --spacing-x:   fn.foreign-dim(--message-group, --spacing-x),
            --gap:         fn.global-dim(--size --300),
            --gap-lg:      fn.global-dim(--size --600),
            --gap-sm:      fn.global-dim(--size --75),
        )
    ), 'dims');

    @include iro.bem-layout(iro.props-namespace()) {
        display:        flex;
        flex-direction: column;

        @include iro.bem-elem('item') {
            @include iro.bem-next-twin-elem {
                margin-top: fn.dim(--gap-lg);

                @include iro.bem-modifier('group') {
                    margin-top: fn.dim(--gap-sm);
                }
            }

            @include iro.bem-modifier('self') {
                @include iro.bem-elem('item-message') {
                    &::before {
                        display: none;
                    }
                }
            }

            @include iro.bem-modifier('message') {
                @include iro.bem-next-twin-elem {
                    @include iro.bem-modifier('message') {
                        margin-top: fn.dim(--gap);
                    }
                }
            }

            @include iro.bem-modifier('event') {
                @include iro.bem-next-twin-elem {
                    @include iro.bem-modifier('event') {
                        margin-top: fn.dim(--gap-sm);
                    }
                }
            }

            @include iro.bem-modifier('note') {
                @include iro.bem-next-twin-elem {
                    @include iro.bem-modifier('note') {
                        margin-top: fn.dim(--gap-sm);
                    }
                }
            }

            @include iro.bem-modifier('reply') {
                @include iro.bem-next-twin-elem {
                    @include iro.bem-modifier('reply') {
                        margin-top: fn.dim(--gap-sm);
                    }
                }
            }

            @include iro.bem-modifier('divider') {
                @include iro.bem-next-twin-elem {
                    @include iro.bem-modifier('divider') {
                        margin-top: fn.dim(--gap-sm);
                    }
                }
            }
        }

        @include iro.bem-modifier('bubbles') {
            @include iro.bem-elem('item') {
                justify-items: start;

                @include iro.bem-modifier('self') {
                    grid-template-columns: 1fr;
                    grid-template-areas:   'message';
                    justify-items:         end;
                    margin-left:           calc(fn.dim(--avatar) + fn.dim(--spacing-x));
    
                    @include iro.bem-elem('item-header', 'item-avatar') {
                        display: none;
                    }
                }
            }
        }

        @include iro.bem-modifier('linear') {
            @include iro.bem-elem('item-avatar') {
                position: relative;
                top:      .2em;
            }

            @include iro.bem-elem('item-message') {
                &::before {
                    display: none;
                }
            }

            @include iro.bem-elem('item') {
                @include iro.bem-modifier('message') {
                    @include iro.bem-next-twin-elem {
                        /* stylelint-disable-next-line max-nesting-depth */
                        @include iro.bem-modifier('group') {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}
