@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;

@include iro.props-namespace('fullscreen') {
    @include iro.bem-layout(iro.props-namespace()) {
        grid-area: 1 / 1 / -1 / -1;
        overflow:  auto;

        @include iro.bem-modifier('centered') {
            align-self:   center;
            justify-self: center;
        }
    }
}
