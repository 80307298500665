@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;

@include iro.props-namespace('avatar') {
    @include iro.bem-object(iro.props-namespace()) {
        @include iro.bem-elem('content') {
            clip-path: url('../../../assets/symbols.svg#squircle');
        }
    }
}
