@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;

@include iro.props-namespace('sidebar') {
    @include iro.props-store((
        --dims: (
            --min-width:  250px,
            --max-width:  30vw,
            --pad-x:      fn.global-dim(--size --250),
            --pad-y:      fn.global-dim(--size --250),
            
        )
    ), 'dims');

    @include iro.bem-layout(iro.props-namespace()) {
        display:         flex;
        flex-direction:  column;
        min-width:       fn.dim(--min-width);
        max-width:       fn.dim(--max-width);
        overflow:        auto;
        scrollbar-color: fn.global-color(--fg-hi3) fn.global-color(--bg);

        @include iro.bem-elem('content') {
            flex:    1 1 auto;
            padding: fn.dim(--pad-y) fn.dim(--pad-x);
        }

        @include iro.bem-modifier('left') {
            grid-area: left;
        }

        @include iro.bem-modifier('right') {
            grid-area: right;
        }
    }
}
