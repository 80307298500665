@use 'iro-sass/src/index' as iro;

@include iro.props-namespace('icon') {
    @include iro.props-store((
        --dims: (
            --size: calc(1 / 14 * 16em),
        )
    ), 'dims');

    @include iro.bem-object(iro.props-namespace()) {
        @include iro.bem-modifier('large') {
            stroke-width: var(--icon-stroke-width, 1px);
            font-size:    4em;
        }

        @include iro.bem-modifier('loading') {
            stroke-width: var(--icon-stroke-width, 2px);

            @include iro.bem-modifier('large') {
                stroke-width: var(--icon-stroke-width, 1.5px);
            }
        }
    }
}

@keyframes loading-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes loading-dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 30, 200;
        stroke-dashoffset: -12px;
    }

    100% {
        stroke-dasharray: 30, 200;
        stroke-dashoffset: -43px;
    }
}
