@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;
@use 'include-media/dist/include-media' as media;

@include iro.props-namespace('header') {
    @include iro.props-store((
        --dims: (
            --height:   fn.global-dim(--size --700),
            --pad-x:    fn.global-dim(--size --250),
            --pad-x-sm: fn.global-dim(--size --100),
            --border:   1px,

            --side: (
                --pad-x: fn.global-dim(--size --250),
            ),
        )
    ), 'dims');

    @include iro.props-store((
        --colors: (
            --border: fn.global-color(--border --stable),
        ),
    ), 'colors');

    @include iro.bem-layout(iro.props-namespace()) {
        position:         sticky;
        z-index:          1000;
        top:              0;
        box-sizing:       border-box;
        flex:             0 0 auto;
        min-width:        0;
        height:           fn.dim(--height);
        padding-right:    fn.dim(--pad-x);
        padding-left:     fn.dim(--pad-x);
        border-bottom:    fn.dim(--border) solid fn.color(--border);
        background-color: fn.global-color(--bg);

        @include iro.bem-modifier('side') {
            padding: 0 fn.dim(--side --pad-x);
        }

        @include iro.bem-elem('button-start') {
            margin-left:  calc(fn.dim(--pad-x-sm) - fn.dim(--pad-x));
        }

        @include iro.bem-elem('button-end') {
            margin-right: calc(fn.dim(--pad-x-sm) - fn.dim(--pad-x));
        }

        @include media.media('height<=400px') {
            position: static;
        }
    }
}
