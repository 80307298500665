@use 'iro-sass/src/index' as iro;
@use 'iro-design/src/functions' as fn;

@include iro.props-namespace('mention') {
    @include iro.props-store((
        --colors: (
            --h: 354,
            --s: 64%,
            --l: 30%,
        ),
    ), 'colors');

    @include iro.props-store((
        --colors: (
            --s: 54%,
            --l: 70%,
        ),
    ), 'colors-dark');

    @include iro.bem-object(iro.props-namespace()) {
        color: hsl(fn.color(--h), fn.color(--s), fn.color(--l));
    }
}
